import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Contact from './Pages/ContactUs';
// import Brands from './components/Brands';
// import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import AboutUs from './Pages/AboutUs';
function App() {
  return (
    <Router>
    <Navbar />
    <Routes>
      <Route path="/home" element={<Home />} />
      <Route path="/" element={<Home />} />
      {/* <Route path="/products" element={<Products />} /> */}
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<Contact />} />
    </Routes>
    <Footer />
  </Router>
  );
}

export default App;

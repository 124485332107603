import React from 'react';
import './AboutUs.css'; // Make sure to create and import a CSS file for styling
import aboutUsImage from '../Assets/Events/image2.jpg'; // Import your image

const AboutUs = () => {
  return (
    <div className="about-container">
      <div className="about-content">
        <div className="aboutUs-image-container">
          <img src={aboutUsImage} alt="About Us" className="aboutUs-image" />
        </div>
        <div className="aboutUs-text-container">
          <div className="company-name">
            ARTS SILK HOUSE
          </div>
          <div className="company-intro">
            <h3>Company CEO: Mr Sunil Bajoria</h3>
            <p>
              Established 35 years ago, Art Silk House has grown into a leading textile company under the visionary leadership of CEO Sunil Bajoria. We have been at the forefront of innovation and quality in the textile industry, continually adapting to the changing market demands and setting new standards of excellence.
            </p>
            <p>
              Eight years ago, we ventured into the manufacturing of curtains, bringing the same level of dedication and craftsmanship that has defined our brand. Our curtains are crafted with precision and care, ensuring that they not only enhance the beauty of homes but also stand the test of time.
            </p>
            <p>
              Today, Art Silk House is being taken forward by Yash Bajoria, son of Sunil Bajoria, who is committed to upholding the family tradition of quality and innovation. Under his dynamic leadership, we continue to explore new horizons, expand our product range, and cater to a growing customer base with unparalleled service.
            </p>
          </div>
          <div className="company-products">
            <h3>Our Products</h3>
            <p>
              At Art Silk House, we believe in creating products that blend aesthetic appeal with functionality. Our extensive experience and passion for textiles drive us to produce the finest fabrics that add a touch of elegance to any space. Join us on our journey as we continue to weave the future of textiles.
            </p>
          </div>
          <table className="company-details">
            <tbody>
              <tr>
                <th>Registration Number</th>
                <td>0137276</td>
              </tr>
              <tr>
                <th>GST No</th>
                <td>27AAGPB5233N1ZO</td>
              </tr>
              <tr>
                <th>Class of Company</th>
                <td>Private</td>
              </tr>
              <tr>
                <th>Year of Incorporation</th>
                <td>1989</td>
              </tr>
              <tr>
                <th>Activity</th>
                <td>Manufacturing and Trading</td>
              </tr>
              <tr>
                <th>Address</th>
                <td> 33A kanbai chawl , khadilkar road ,
Above steel market 
1 st floor near, Mumbai, Maharashtra 400004</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

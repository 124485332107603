import React, { useState } from 'react';
// import Slider from 'react-slick';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ImageGallery from 'react-image-gallery';
import { Modal } from 'react-bootstrap';
import './Home.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-gallery/styles/css/image-gallery.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import image1 from '../Assets/RUBY-02.jpg';
import image2 from '../Assets/LYON-01.jpg';
import image3 from '../Assets/ROME-02.jpg';
import eventImage1 from '../Assets/Events/image1.jpg';
import eventImage3 from '../Assets/Events/image3.jpg';
import eventImage4 from '../Assets/Events/image4.jpg';
import upholsteryImage1 from '../Assets/Upholstery/image1.jpg';
import upholsteryImage2 from '../Assets/Upholstery/image2.jpg';
import upholsteryImage3 from '../Assets/Upholstery/image3.jpg';
import upholsteryImage4 from '../Assets/Upholstery/image4.jpg';
import upholsteryImage5 from '../Assets/Upholstery/image5.jpg';
import upholsteryImage6 from '../Assets/Upholstery/image6.jpg';
import upholsteryImage7 from '../Assets/Upholstery/image7.jpg';
import curatinsimage1 from '../Assets/FOREST-01.jpg';
import curtainsimage2 from '../Assets/SYDNEY-01.jpg';
import curtainsimage3 from '../Assets/TOKYO-02.jpg';
import curtainsimage4 from '../Assets/VIENNA-02.jpg';

const curtainsImages = [
  {
    original: curatinsimage1,
    thumbnail: curatinsimage1,
  },
  {
    original: curtainsimage2,
    thumbnail: curtainsimage2,
  },
  {
    original: curtainsimage3,
    thumbnail: curtainsimage3,
  },
  {
    original: curtainsimage4,
    thumbnail: curtainsimage4,
  }
];

const upholsteryImages = [
  {
    original: upholsteryImage1,
    thumbnail: upholsteryImage1,
  },
  {
    original: upholsteryImage2,
    thumbnail: upholsteryImage2,
  },
  {
    original: upholsteryImage3,
    thumbnail: upholsteryImage3,
  },
  {
    original: upholsteryImage4,
    thumbnail: upholsteryImage4,
  },
  {
    original: upholsteryImage5,
    thumbnail: upholsteryImage5,
  },
  {
    original: upholsteryImage6,
    thumbnail: upholsteryImage6,
  },
  {
    original: upholsteryImage7,
    thumbnail: upholsteryImage7,
  },
];

function Home() {
  const [showModal, setShowModal] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [modalTitle, setModalTitle] = useState('');

  const handleShowGallery = (images, title) => {
    setGalleryImages(images);
    setModalTitle(title);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className="wrapper">
      <div className="content">
      <div className="carousel-container">
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={image1}
              alt="Decorative Charcoal Sheet"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={image2}
              alt="Sainik Laminated Sheet"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={image3}
              alt="Slat Louvers Panels"
            />
          </Carousel.Item>
        </Carousel>
      </div>

        <div className="collections-section">
          <p className="collections-description"></p>
          <h2 className="collections-title">COLLECTIONS</h2>
          <p className="collections-description">Our collections are crafted with precision and an eye for detail, ensuring the highest quality and style.
            <br />Explore our range of curtains and upholstery to find the perfect match for your home.
          </p>
          <div className="collections-container">
            <div 
              className="collection-item curtains-link" 
              onClick={() => handleShowGallery(curtainsImages, 'CURTAINS')}
            >
              <div className="collection-image curtains">
                <span className="collection-label">CURTAINS</span>
              </div>
            </div>
            <div 
              className="collection-item upholstery-link" 
              onClick={() => handleShowGallery(upholsteryImages, 'UPHOLSTERY')}
            >
              <div className="collection-image upholstery">
                <span className="collection-label">UPHOLSTERY</span>
              </div>
            </div>
          </div>
        </div>

        <div className="events-section">
          <h2 className="events-title">Latest Events</h2>
          <p className="events-subtitle">FIFEX EXHIBITION MAY 2024 (Kerala)</p>
          <div className="events-container">
            <div className="event-item">
              <img src={eventImage1} alt="Event 1" />
            </div>
            <div className="event-item">
              <img src={eventImage3} alt="Event 3" />
            </div>
            <div className="event-item">
              <img src={eventImage4} alt="Event 4" />
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleClose} size="md" centered>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageGallery items={galleryImages} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Home;

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Ensure Bootstrap JS is imported
import { Link } from 'react-router-dom';
import logo from '../Assets/logo.png';
import './Navbar.css'; // Import the CSS file for custom styling

const Navbar = () => {
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center p-2" style={{ backgroundColor: 'white' }}>
        <div>
          <img src={logo} alt="Art Silk House" className="m-0" style={{ height: '60px', marginLeft: '-15px' }} />
        </div>
        <div style={{ textAlign: 'right' }}>
          <p className="m-0">Charni Road, Mumbai, Maharashtra</p>
          <p className="m-0">GST No.27AAGPB5233N1ZO</p>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
            <ul className="navbar-nav">
              {/* <li className="nav-item">
                <Link to="/products" className="nav-link">Our Products</Link>
              </li> */}
              <li className="nav-item">
                <Link to="/home" className="nav-link">Home</Link>
              </li>
              <li className="nav-item">
                <Link to="/about-us" className="nav-link">About Us</Link>
              </li>
              <li className="nav-item">
                <Link to="/contact-us" className="nav-link">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="d-none d-lg-block">
            {/* Any additional content */}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;

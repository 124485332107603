import React from 'react';
import './Footer.css';
// import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import logo from '../Assets/logo.png';
function Footer() {
  
    const navigate = useNavigate();
  
    const navigateToAboutUs = () => {
      navigate('/about-us');
    };
  
    const navigateToContactUs = () => {
      navigate('/contact-us');
    };
  return (
    <>
    <div className="info-section">
    <div className="info-box about-us-box" onClick={navigateToAboutUs}>
      <h2>About Us</h2>
      <p>Learn more about our journey, mission, and the people behind our success.</p>
    </div>
    <div className="info-box contact-us-box" onClick={navigateToContactUs}>
      <h2>Contact Us</h2>
      <p>Get in touch with us for any inquiries or support.</p>
    </div>
  </div>
  <footer className="footer">
            <div className="footer-content">
                <div className="footer-left">
                    <div className="footer-logo">
                        <img src={logo} alt="Gibigiaana Logo" />
                    </div>
                    <address>
                        <p>Art Silk House <br/>
                        33A kanbai chawl , khadilkar road,<br/>
                        Above steel market 
                        <br/> 1 st floor near, Mumbai, Maharashtra 400004</p>
                        <p>📞 (022) 6502 3425</p>
                        <p>WhatsApp: (+91)93221 84865</p>
                    </address>
                </div>
                <div className="footer-center">
                    <h3>OPENING HOURS</h3>
                    <p><strong>Monday - Saturday</strong></p>
                    <p>10.30 am / 7.00pm</p>
                    <p><strong>Closed: Sunday </strong> </p>
                </div>
              
            </div>
        </footer>
    </>
  );
}

export default Footer;
